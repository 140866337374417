import { useLocation } from "react-router-dom";
import Promo from "../../../components/organs/promo";
import { useState } from "react";

function Details() {
    const state = useLocation();
    const [displayImage, setDisplayImage] = useState(state.state.data.productImageList[0]);

  return (
    <div>
        <Promo/>
        <section className="py-12 sm:py-16"> 
            <div className="container mx-auto px-4">
                
                <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                    <div className="lg:col-span-3 lg:row-end-1">
                        <div className="lg:flex lg:items-start">
                        <div className="lg:order-2 lg:ml-5">
                            <div className="max-w-xl overflow-hidden rounded-lg">
                            <img className="h-full w-full max-w-full object-cover" src={displayImage} alt="thumbnail" />
                            </div>
                        </div>

                        <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                            <div className="flex flex-row items-start lg:flex-col">
                                {
                                    state.state.data.productImageList.map((data, index) => {
                                        return (
                                            <button key={index} onClick={() => setDisplayImage(data)} type="button" className="flex-0 aspect-square mb-3 h-20 overflow-hidden text-center rounded-lg">
                                                <img className="h-full w-full object-cover" src={data} alt="" />
                                            </button>
                                        )
                                    })
                                }
                               
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2 tracking-[0.2rem]">
                        <p className="text-secondary text-sm">FLYMANNATHALIE</p>

                        <h2 className="my-2 font-normal text-gray-800 text-2xl md:text-2xl">FLYMAN CELANA DALAM PRIA ICE SILK 3D TEXTURE CLANA DALEM CD COWOK MICRO NYLON 1 PCS FM 3446</h2>
                        <div className="flex items-center space-x-4 my-4">
                            <span className=" text-primary text-xl tracking-widest">RP 33.500,00</span>
                        </div>

                        <hr/>
                        <div className="text-secondary tracking-normal">
                            <p className="my-5">Flyman Celana Olahraga Panjang Pria Cln Sport Premium Quickdry Jogger Pants Cowok 1 Pcs FM 3462</p>
                            <p className="my-5">- Sensasi dingin dengan teknologi ice silk</p>
                            <p className="my-5">- Bahan elastis dan nyaman</p>
                            <p className="my-5">- Celana ringan dan tidak lembab sehingga bau tidak menempel pada pakaian saat berkeringat</p>
                            <p className="my-5">- Karet fleksibel dapat disesuaikan dengan pinggang</p>
                            <p className="my-5">- Terdapat kantong di sisi kiri dan kanan</p>
                            <p className="my-5">- Jahitan rapi dan tahan lama</p>
                        </div>
                        
                    
                    </div>

                
                </div>
            </div>
        </section>
    </div>
  );
}

export default Details;
