export const navbar = [
    {
        id: 0,
        menu: "Our Brand",
        total: 1,
        list: [{
            id: 0,
            category: "OUR BRANDS",
            menuList: [
                {
                    name: "PIERRE UNO",
                    link: ""
                },
                {
                    name: "JORDAN CAVALLI",
                    link: ""
                },
                {
                    name: "BEYONDSKIN",
                    link: ""
                },
                {
                    name: "RUDES & SON",
                    link: ""
                },
                {
                    name: "ARROW APPLE",
                    link: ""
                },
                {
                    name: "CATASY",
                    link: ""
                },
                {
                    name: "PIERRE UNO KIDS",
                    link: ""
                },
                {
                    name: "FINY GIRLS",
                    link: ""
                },
            ]
        }]
    },
    {
        id: 0,
        menu: "MEN",
        total: 4,
        list: [
            {
                id: 0,
                category: "PIERRE UNO",
                menuList: [
                    {
                        name: "PIERRE UNO",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    },
                    {
                        name: "Sleep/Lounge",
                        link: ""
                    },
                ]
            },
            {
                id: 1,
                category: "JORDAN CAVALLI",
                menuList: [
                    {
                        name: "JORDAN CAVALLI",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    }
                ]
            },
            {
                id: 2,
                category: "BEYONDSKIN",
                menuList: [
                    {
                        name: "BEYONDSKIN",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    }
                ]
            },
            {
                id: 3,
                category: "RUDES & SON",
                menuList: [
                    {
                        name: "RUDES & SON",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    },
                    {
                        name: "Sleep/Lounge",
                        link: ""
                    },
                ]
            }
        ]
    },
    {
        id: 0,
        menu: "MEN",
        total: 2,
        list: [
            {
                id: 0,
                category: "ARROW APPLE",
                menuList: [
                    {
                        name: "ARROW APPLE",
                        link: ""
                    },
                    {
                        name: "All Products",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    },
                ]
            },
            {
                id: 1,
                category: "STYL",
                menuList: [
                    {
                        name: "STYL ",
                        link: ""
                    },
                    {
                        name: "All Products ",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Pajamas",
                        link: ""
                    },
                    {
                        name: "Nightgowns",
                        link: ""
                    }
                ]
            }
        ]
    }
]

export const DataProducts = [
    {
        productName: "laron",
        productPrice: "Rp 45000",
        productSize: "XL",
        ProductColor: "blue",
        productImage: "/img/product/1.png"
    },
    {
        productName: "laron",
        productPrice: "Rp 45000",
        productSize: "XL",
        ProductColor: "blue",
        productImage: "../img/product/2.png"
    },
    {
        productName: "laron",
        productPrice: "Rp 45000",
        productSize: "XL",
        ProductColor: "blue",
        productImage: "../img/product/3.png"
    },
    {
        productName: "laron",
        productPrice: "Rp 45000",
        productSize: "XL",
        ProductColor: "blue",
        productImage: "../img/product/4.png"
    }
]

export const DataProduct = [
    {
        id: 0,
        category: "All",
        slug: "all-mens",
        type: "MENS",
        listProduct: [
            {
                productName: "JC Boy",
                productPrice: "Rp 45000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "/img/jc_boy/1.png",
                    "/img/jc_boy/2.png",
                    "/img/jc_boy/3.png",
                    "/img/jc_boy/4.png",
                    "/img/jc_boy/5.png",
                    "/img/jc_boy/6.png",
                    "/img/jc_boy/7.png",
                    "/img/jc_boy/8.png",
                    "/img/jc_boy/9.png",
                    "/img/jc_boy/10.png",
                ],
                productThumbnail: "/img/jc_boy/1.png"
            },
            {
                productName: "Pierre Uno",
                productPrice: "Rp 45000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "/img/pu/pu-boxer1/bx1.PNG",
                    "/img/pu/pu-boxer1/bx2.PNG",
                    "/img/pu/pu-boxer1/bx3.PNG",
                    "/img/pu/pu-boxer1/bx4.PNG",
                    "/img/pu/pu-boxer1/bx5.PNG",
                    "/img/pu/pu-boxer1/bx6.PNG",
                ],
                productThumbnail: "/img/pu/pu-boxer1/bx1.PNG"
            },
            {
                productName: "JC Boy",
                productPrice: "Rp 45000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/pu/pu-boxer2/bx1.PNG",
                    "../img/pu/pu-boxer2/bx2.PNG",
                    "../img/pu/pu-boxer2/bx3.PNG",
                    "../img/pu/pu-boxer2/bx4.PNG",
                    "../img/pu/pu-boxer2/bx5.PNG",
                    "../img/pu/pu-boxer2/bx6.PNG",
                ],
                productThumbnail: "../img/pu/pu-boxer2/bx1.PNG"
            },
            {
                productName: "JC Boy",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/pu/pu-b01m/img_0021.PNG",
                    "../img/pu/pu-b01m/img_0026.PNG",
                    "../img/pu/pu-b01m/img_0030.PNG",
                    "../img/pu/pu-b01m/img_0032.PNG",
                ],
                productThumbnail: "../img/pu/pu-b01m/img_0021.PNG"
            },
            {
                productName: "JC Boy",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/pu/pu-b05m/img_0089.PNG",
                    "../img/pu/pu-b05m/img_0092.PNG",
                    "../img/pu/pu-b05m/img_0096.PNG",
                    "../img/pu/pu-b05m/img_0097.PNG",
                    "../img/pu/pu-b05m/img_0098.PNG",
                    "../img/pu/pu-b05m/img_0099.PNG",
                ],
                productThumbnail: "../img/pu/pu-b05m/img_0089.PNG"
            },
            {
                productName: "P+1",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/p+1/po-bs01 m_001.PNG",
                    "../img/p+1/po-bs01 m_002.PNG",
                    "../img/p+1/po-bs01 m_003.PNG",
                    "../img/p+1/po-bs01 m_006.PNG",
                    "../img/p+1/PO-BS01 M_007.PNG",
                    "../img/p+1/po-bs01 m_008.PNG",
                ],
                productThumbnail: "../img/p+1/po-bs01 m_001.PNG"
            }
        ]
    },
    // {
    //     id: 0,
    //     category: "All",
    //     slug: "all-women",
    //     type: "WOMEN",
    //     listProduct: [
    //         {
    //             productName: "AA-B01 F (Rio-Best Seller)",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0041.PNG",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0045.PNG",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0047.PNG",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0051.PNG",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0053.PNG",
    //             ],
    //             productThumbnail: "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0041.PNG"
    //         },
    //         {
    //             productName: "AA-B02 F (Alfa)",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0003.PNG",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0011.PNG",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0012.PNG",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0019.PNG",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0021.PNG",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0027.PNG",
    //             ],
    //             productThumbnail: "../img/women/AA/AA-B02 F (Alfa)/img_0003.PNG"
    //         }, 
    //         {
    //             productName: "AA-B04 F (Cotton)",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0157.PNG",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0160.PNG",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0162.PNG",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0165.PNG",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0166.PNG",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0168.PNG",
    //             ],
    //             productThumbnail: "../img/women/AA/AA-B04 F (Cotton)/img_0157.PNG"
    //         }
    //     ]
    // },
    // {
    //     id: 0,
    //     category: "All",
    //     slug: "all-teens",
    //     type: "TEENS",
    //     listProduct: [
    //         {
    //             productName: "AA-S01",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/AA/AA-S01 BM_001.PNG",
    //                 "../img/teens/AA/AA-S01 BM_002.PNG",
    //                 "../img/teens/AA/AA-S01 BM_003.PNG",
    //                 "../img/teens/AA/AA-S01 BM_004.PNG",
    //                 "../img/teens/AA/AA-S01 BM_005.PNG",
    //             ],
    //             productThumbnail: "../img/teens/AA/AA-S01 BM_001.PNG"
    //         },
    //         {
    //             productName: "JC-BR01 T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.PNG",
    //                 "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.PNG",
    //                 "../img/teens/JC-BR01 T/20230909-F JIO(1)  IMG_0498.PNG",
    //                 "../img/teens/JC-BR01 T/IMG_0495.PNG",
    //                 "../img/teens/JC-BR01 T/IMG_0501.PNG",
    //             ],
    //             productThumbnail: "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.PNG",

    //         }, 
    //         {
    //             productName: "PU-BR02T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/PU-BR02T/IMG_0037.PNG",
    //                 "../img/teens/PU-BR02T/IMG_0038.PNG",
    //                 "../img/teens/PU-BR02T/IMG_0040.PNG",
    //                 "../img/teens/PU-BR02T/IMG_0044.PNG",
    //                 "../img/teens/PU-BR02T/IMG_0047.PNG",
    //             ],
    //             productThumbnail: "../img/teens/PU-BR02T/IMG_0037.PNG",
    //         }, 
    //         {
    //             productName: "PU-BS01T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/PU-BS01T/IMG_0104.PNG",
    //                 "../img/teens/PU-BS01T/IMG_0106.PNG",
    //                 "../img/teens/PU-BS01T/IMG_0108.PNG",
    //             ],
    //             productThumbnail: "../img/teens/PU-BS01T/IMG_0104.PNG",
    //         }, 
    //         {
    //             productName: "PU-BS09T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/PU-BS09T/IMG_0235.PNG",
    //                 "../img/teens/PU-BS09T/IMG_0239.PNG",
    //                 "../img/teens/PU-BS09T/IMG_0246.PNG",
    //             ],
    //             productThumbnail: "../img/teens/PU-BS09T/IMG_0235.PNG",
    //         }, 
    //     ]
    // },
    {
        id: 0,
        category: "All",
        slug: "all-kids",
        type: "KIDS",
        listProduct: [
            {
                productName: "FI-B02 G (CD. Neci)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/2.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/cd2.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0035.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0044.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0279.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0281.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0283.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0286.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0288.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0294.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/2.png",
            },
            {
                productName: "PU-KIDS 1X1 +WM",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_02.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_03.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_04.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_05.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_06.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_07.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL.png",
            },  
            {
                productName: "FI-B03 G (CD Animal Cartoon)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0096.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0098.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0333.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0355.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0358.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0360.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0361.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0363.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0369.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.PNG",
            },  
            {
                productName: "FI-B04 G (CD Dream)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0338.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0428.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0430.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0431.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0435.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0436.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0338.PNG",
            },  
            {
                productName: "FI-B15 G (CD Kimono)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/4.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0064.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0260.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0262.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0264.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0270.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0272.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/4.png",
            },   
            {
                productName: "FI-B16 G (Girl Only)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/7.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0102.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0113.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0344.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0347.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0349.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0352.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0353.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/7.png",
            }, 
            {
                productName: "FI-B17 G (CD Leap Before Sleep)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/3.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0133.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0137.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0404.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0406.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0408.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0413.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0414.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/3.png",
            },  
            {
                productName: "FI-B18 G (Little Girl)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/5.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0077.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0085.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0086.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0371.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0373.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0375.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0378.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0381.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/5.png",
            },
            {
                productName: "FI-B20 G (CD Animal Face 01)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/1.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/9.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0047.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0299.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0303.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0312.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/1.png",
            },
            {
                productName: "FI-B21 G (CD Femmiephant)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/8.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0003.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0009.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0016.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0313.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0317.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0318.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0325.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/8.png",
            }, 
            {
                productName: "FI-B26 G (CD Bunny (Best Seller)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0390.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0393.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0394.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0397.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0399.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0401.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.PNG",
            }, 
            {
                productName: "FI-BP11 G (CD Bow Fuschia)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/6.png",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0070.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0336.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0417.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0418.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0423.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0425.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0426.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/6.png",
            },   
            {
                productName: "FI-BR25 G (CD Animal Face 02)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/10.png",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0020.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0249.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0251.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0254.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0258.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/10.png",
            },
            {
                productName: "FI-S01 G (Girl Singlet)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0131.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0144.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0262.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0265.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0269.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0272.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0274.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0280.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0286.PNG",

                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.PNG",
            },
            {
                productName: "PU-B02 B (CD Dino)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0146.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0152.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0202.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0204.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0207.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0209.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.PNG",
            }, 
            {
                productName: "PU-B04 B (CD Safari)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0176.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0177.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0178.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0180.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0184.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0187.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0190.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0192.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.PNG",
            }, 
            {
                productName: "PU-B05 B (CD Car (Best Seller)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0066.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0074.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0082.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0186.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0191.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0197.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.PNG",
            }, 
            {
                productName: "PU-B06 B (CD Polos)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0122.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0125.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0127.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0133.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0254.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0255.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0261.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0262.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.PNG",
            },
            {
                productName: "PU-B08 B (CD Alien)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0094.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0098.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0101.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0106.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0243.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0244.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0252.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0253.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.PNG",
            }, 
            {
                productName: "PU-B10 B (CD Builder)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0211.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0216.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0221.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0223.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0227.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0229.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0231.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0234.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.PNG",
            }, 
            {
                productName: "PU-B13 B (CD Pirates)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0008.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0013.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0017.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0018.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0023.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0231.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0235.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0237.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0241.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.PNG",
            }, 
            {
                productName: "PU-BR12 B (CD Team Cat)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0142.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0149.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0155.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0159.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0169.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0201.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0205.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0211.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0214.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.PNG",
            }, 
            {
                productName: "PU-BR14 B (CD Space Craft)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0034.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0036.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0045.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0049.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0218.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0219.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0225.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0226.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0228.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.PNG",
            },
            {
                productName: "PU-S01 B (Boy_s Singlet)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/Green.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/White.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/Aqua.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/grey.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/deep yellow.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/sky blue.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0190.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0193.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0195.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0221.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0224.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0240.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0243.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0245.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0265.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0277.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0282.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/Green.PNG",
            }, 
        ]
    },
    {
        id: 0,
        category: "Jordan Cavalli",
        slug: "jordan-cavalli",
        type: "MENS",
        listProduct: [
            {
                productName: "JC Boy",
                productPrice: "Rp 45000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "/img/jc_boy/1.png",
                    "/img/jc_boy/2.png",
                    "/img/jc_boy/3.png",
                    "/img/jc_boy/4.png",
                    "/img/jc_boy/5.png",
                    "/img/jc_boy/6.png",
                    "/img/jc_boy/7.png",
                    "/img/jc_boy/8.png",
                    "/img/jc_boy/9.png",
                    "/img/jc_boy/10.png",
                ],
                productThumbnail: "/img/jc_boy/1.png"
            },
        ]
    },
    {
        id: 1,
        category: "Pierre Uno",
        slug: "pierre-uno",
        type: "MENS",
        listProduct: [
            {
                productName: "Pierre Uno",
                productPrice: "Rp 45000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "/img/pu/pu-boxer1/bx1.PNG",
                    "/img/pu/pu-boxer1/bx2.PNG",
                    "/img/pu/pu-boxer1/bx3.PNG",
                    "/img/pu/pu-boxer1/bx4.PNG",
                    "/img/pu/pu-boxer1/bx5.PNG",
                    "/img/pu/pu-boxer1/bx6.PNG",
                ],
                productThumbnail: "/img/pu/pu-boxer1/bx1.PNG"
            },
            {
                productName: "JC Boy",
                productPrice: "Rp 45000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/pu/pu-boxer2/bx1.PNG",
                    "../img/pu/pu-boxer2/bx2.PNG",
                    "../img/pu/pu-boxer2/bx3.PNG",
                    "../img/pu/pu-boxer2/bx4.PNG",
                    "../img/pu/pu-boxer2/bx5.PNG",
                    "../img/pu/pu-boxer2/bx6.PNG",
                ],
                productThumbnail: "../img/pu/pu-boxer2/bx1.PNG"
            },
            {
                productName: "JC Boy",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/pu/pu-b01m/img_0021.PNG",
                    "../img/pu/pu-b01m/img_0026.PNG",
                    "../img/pu/pu-b01m/img_0030.PNG",
                    "../img/pu/pu-b01m/img_0032.PNG",
                ],
                productThumbnail: "../img/pu/pu-b01m/img_0021.PNG"
            },
            {
                productName: "JC Boy",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/pu/pu-b05m/img_0089.PNG",
                    "../img/pu/pu-b05m/img_0092.PNG",
                    "../img/pu/pu-b05m/img_0096.PNG",
                    "../img/pu/pu-b05m/img_0097.PNG",
                    "../img/pu/pu-b05m/img_0098.PNG",
                    "../img/pu/pu-b05m/img_0099.PNG",
                ],
                productThumbnail: "../img/pu/pu-b05m/img_0089.PNG"
            },
        ]
    },
    {
        id: 2,
        category: "P+1",
        slug: "p+1",
        type: "MENS",
        listProduct: [
            {
                productName: "P+1",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/p+1/po-bs01 m_001.PNG",
                    "../img/p+1/po-bs01 m_002.PNG",
                    "../img/p+1/po-bs01 m_003.PNG",
                    "../img/p+1/po-bs01 m_006.PNG",
                    "../img/p+1/PO-BS01 M_007.PNG",
                    "../img/p+1/po-bs01 m_008.PNG",
                ],
                productThumbnail: "../img/p+1/po-bs01 m_001.PNG"
            }
        ]
    },
    // {
    //     id: 3,
    //     category: "Arrow Apple",
    //     slug: "arrow-apple",
    //     type: "WOMEN",
    //     listProduct: [
    //         {
    //             productName: "AA-B01 F (Rio-Best Seller)",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0041.PNG",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0045.PNG",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0047.PNG",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0051.PNG",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0053.PNG",
    //             ],
    //             productThumbnail: "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0041.PNG"
    //         },
    //         {
    //             productName: "AA-B02 F (Alfa)",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0003.PNG",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0011.PNG",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0012.PNG",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0019.PNG",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0021.PNG",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0027.PNG",
    //             ],
    //             productThumbnail: "../img/women/AA/AA-B02 F (Alfa)/img_0003.PNG"
    //         }, 
    //         {
    //             productName: "AA-B04 F (Cotton)",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0157.PNG",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0160.PNG",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0162.PNG",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0165.PNG",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0166.PNG",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0168.PNG",
    //             ],
    //             productThumbnail: "../img/women/AA/AA-B04 F (Cotton)/img_0157.PNG"
    //         }
    //     ]
    // },
    // {
    //     id: 3,
    //     category: "Arrow Apple",
    //     slug: "teens-arrow-apple",
    //     type: "TEENS",
    //     listProduct: [
    //         {
    //             productName: "AA-S01",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/AA/AA-S01 BM_001.PNG",
    //                 "../img/teens/AA/AA-S01 BM_002.PNG",
    //                 "../img/teens/AA/AA-S01 BM_003.PNG",
    //                 "../img/teens/AA/AA-S01 BM_004.PNG",
    //                 "../img/teens/AA/AA-S01 BM_005.PNG",
    //             ],
    //             productThumbnail: "../img/teens/AA/AA-S01 BM_001.PNG"
    //         },
    //     ]
    // },
    // {
    //     id: 3,
    //     category: "Jordan Cavalli Teens",
    //     slug: "teens-jordan-cavalli",
    //     type: "TEENS",
    //     listProduct: [
    //         {
    //             productName: "JC-BR01 T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.PNG",
    //                 "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.PNG",
    //                 "../img/teens/JC-BR01 T/20230909-F JIO(1)  IMG_0498.PNG",
    //                 "../img/teens/JC-BR01 T/IMG_0495.PNG",
    //                 "../img/teens/JC-BR01 T/IMG_0501.PNG",
    //             ],
    //             productThumbnail: "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.PNG",

    //         }, 
    //     ]
    // },
    // {
    //     id: 3,
    //     category: "Pierre Uno Teens",
    //     slug: "teens-pierre-uno",
    //     type: "TEENS",
    //     listProduct: [
    //         {
    //             productName: "PU-BR02T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/PU-BR02T/IMG_0037.PNG",
    //                 "../img/teens/PU-BR02T/IMG_0038.PNG",
    //                 "../img/teens/PU-BR02T/IMG_0040.PNG",
    //                 "../img/teens/PU-BR02T/IMG_0044.PNG",
    //                 "../img/teens/PU-BR02T/IMG_0047.PNG",
    //             ],
    //             productThumbnail: "../img/teens/PU-BR02T/IMG_0037.PNG",
    //         }, 
    //         {
    //             productName: "PU-BS01T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/PU-BS01T/IMG_0104.PNG",
    //                 "../img/teens/PU-BS01T/IMG_0106.PNG",
    //                 "../img/teens/PU-BS01T/IMG_0108.PNG",
    //             ],
    //             productThumbnail: "../img/teens/PU-BS01T/IMG_0104.PNG",
    //         }, 
    //         {
    //             productName: "PU-BS09T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/PU-BS09T/IMG_0235.PNG",
    //                 "../img/teens/PU-BS09T/IMG_0239.PNG",
    //                 "../img/teens/PU-BS09T/IMG_0246.PNG",
    //             ],
    //             productThumbnail: "../img/teens/PU-BS09T/IMG_0235.PNG",
    //         }, 
    //     ]
    // },
    {
        id: 3,
        category: "Pierre Uno Kid's",
        slug: "kids-pierre-uno",
        type: "KIDS",
        listProduct: [
            {
                productName: "PU-B02 B (CD Dino)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0146.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0152.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0202.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0204.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0207.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0209.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.PNG",
            }, 
            {
                productName: "PU-B04 B (CD Safari)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0176.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0177.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0178.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0180.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0184.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0187.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0190.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0192.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.PNG",
            }, 
            {
                productName: "PU-B05 B (CD Car (Best Seller)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0066.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0074.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0082.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0186.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0191.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0197.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.PNG",
            }, 
            {
                productName: "PU-B06 B (CD Polos)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0122.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0125.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0127.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0133.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0254.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0255.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0261.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0262.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.PNG",
            },
            {
                productName: "PU-B08 B (CD Alien)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0094.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0098.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0101.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0106.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0243.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0244.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0252.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0253.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.PNG",
            }, 
            {
                productName: "PU-B10 B (CD Builder)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0211.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0216.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0221.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0223.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0227.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0229.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0231.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0234.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.PNG",
            }, 
            {
                productName: "PU-B13 B (CD Pirates)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0008.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0013.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0017.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0018.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0023.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0231.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0235.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0237.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0241.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.PNG",
            }, 
            {
                productName: "PU-BR12 B (CD Team Cat)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0142.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0149.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0155.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0159.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0169.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0201.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0205.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0211.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0214.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.PNG",
            }, 
            {
                productName: "PU-BR14 B (CD Space Craft)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0034.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0036.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0045.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0049.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0218.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0219.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0225.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0226.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0228.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.PNG",
            },
            {
                productName: "PU-KIDS 1X1 +WM",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_02.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_03.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_04.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_05.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_06.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_07.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL.png",
            },
            {
                productName: "PU-S01 B (Boy_s Singlet)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/Green.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/White.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/Aqua.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/grey.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/deep yellow.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/sky blue.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0190.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0193.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0195.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0221.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0224.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0240.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0243.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0245.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0265.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0277.PNG",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0282.PNG",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/Green.PNG",
            },      
        ]
    },
    {
        id: 3,
        category: "Finy Girls Kid's",
        slug: "kids-finy-girls",
        type: "KIDS",
        listProduct: [
            {
                productName: "FI-B02 G (CD. Neci)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/2.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/cd2.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0035.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0044.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0279.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0281.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0283.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0286.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0288.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0294.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/2.png",
            },  
            {
                productName: "FI-B03 G (CD Animal Cartoon)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0096.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0098.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0333.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0355.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0358.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0360.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0361.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0363.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0369.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.PNG",
            },  
            {
                productName: "FI-B04 G (CD Dream)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0338.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0428.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0430.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0431.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0435.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0436.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0338.PNG",
            },  
            {
                productName: "FI-B15 G (CD Kimono)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/4.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0064.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0260.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0262.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0264.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0270.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0272.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/4.png",
            },   
            {
                productName: "FI-B16 G (Girl Only)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/7.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0102.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0113.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0344.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0347.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0349.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0352.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0353.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/7.png",
            }, 
            {
                productName: "FI-B17 G (CD Leap Before Sleep)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/3.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0133.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0137.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0404.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0406.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0408.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0413.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0414.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/3.png",
            },  
            {
                productName: "FI-B18 G (Little Girl)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/5.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0077.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0085.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0086.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0371.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0373.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0375.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0378.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0381.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/5.png",
            },
            {
                productName: "FI-B20 G (CD Animal Face 01)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/1.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/9.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0047.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0299.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0303.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0312.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/1.png",
            },
            {
                productName: "FI-B21 G (CD Femmiephant)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/8.png",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0003.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0009.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0016.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0313.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0317.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0318.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0325.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/8.png",
            }, 
            {
                productName: "FI-B26 G (CD Bunny (Best Seller)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0390.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0393.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0394.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0397.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0399.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0401.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.PNG",
            }, 
            {
                productName: "FI-BP11 G (CD Bow Fuschia)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/6.png",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0070.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0336.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0417.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0418.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0423.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0425.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0426.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/6.png",
            },   
            {
                productName: "FI-BR25 G (CD Animal Face 02)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/10.png",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0020.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0249.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0251.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0254.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0258.PNG",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/10.png",
            },
            {
                productName: "FI-S01 G (Girl Singlet)",
                productPrice: "Rp 45.000",
                productSize: ["S", "M", "L", "XL"],
                ProductColor: ["Yellow", "Green"],
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0131.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0144.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0262.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0265.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0269.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0272.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0274.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0280.PNG",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0286.PNG",

                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.PNG",
            },
        ]
    },
]

export const listCategory = [
    {
        productCategory: "MENS",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-mens"
            },
            {
                productBrand: "PIERRE UNO",
                link: "/collections/pierre-uno"
            },
            {
                productBrand: "Jordan Cavalli",
                link: "/collections/jordan-cavalli"
            },
            {
                productBrand: "P+1",
                link: "/collections/p+1"
            }
        ]
    },
    {
        productCategory: "WOMEN",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-women"
            },
            {
                productBrand: "Arrow Apple",
                link: "/collections/arrow-apple"
            }
        ]
    },
    {
        productCategory: "TEENS",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-teens"
            },
            {
                productBrand: "Arrow Apple",
                link: "/collections/arrow-apple"
            },
            {   productBrand: "Arrow Apple", 
                link: "/collections/teens-arrow-apple" 
            },
            {   productBrand: "Jordan Cavalli", 
                link: "/collections/teens-jordan-cavalli" 
            },
            {   productBrand: "Pierre Uno", 
                link: "/collections/teens-pierre-uno" 
            }
        ]
    },
    {
        productCategory: "KIDS",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-kids"
            },
            { productBrand: "Finy Girls", link: "/collections/kids-finy-girls" },
            { productBrand: "Pierre Uno", link: "/collections/kids-pierre-uno" },
        ]
    },
]

export const newJson = [
    {
        category: "MENS",
        productList: [
            {
                category: "all",
                slug: "all",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.PNG",
                            "/img/jc_boy/2.PNG",
                            "/img/jc_boy/3.PNG",
                            "/img/jc_boy/4.PNG"
                        ],
                        productThumbnail: "/img/jc_boy/1.PNG"
                    },
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.PNG",
                            "/img/pu/pu-boxer1/bx2.PNG",
                            "/img/pu/pu-boxer1/bx3.PNG",
                            "/img/pu/pu-boxer1/bx4.PNG",
                            "/img/pu/pu-boxer1/bx5.PNG",
                            "/img/pu/pu-boxer1/bx6.PNG",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.PNG"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-boxer2/bx1.PNG",
                            "../img/pu/pu-boxer2/bx2.PNG",
                            "../img/pu/pu-boxer2/bx3.PNG",
                            "../img/pu/pu-boxer2/bx4.PNG",
                            "../img/pu/pu-boxer2/bx5.PNG",
                            "../img/pu/pu-boxer2/bx6.PNG",
                        ],
                        productThumbnail: "../img/pu/pu-boxer2/bx1.PNG"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.PNG",
                            "../img/pu/pu-b01m/img_0026.PNG",
                            "../img/pu/pu-b01m/img_0030.PNG",
                            "../img/pu/pu-b01m/img_0032.PNG",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.PNG"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b05m/img_0089.PNG",
                            "../img/pu/pu-b05m/img_0092.PNG",
                            "../img/pu/pu-b05m/img_0096.PNG",
                            "../img/pu/pu-b05m/img_0097.PNG",
                            "../img/pu/pu-b05m/img_0098.PNG",
                            "../img/pu/pu-b05m/img_0099.PNG",
                        ],
                        productThumbnail: "../img/pu/pu-b05m/img_0089.PNG"
                    },
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/po-bs01 m_001.PNG",
                            "../img/p+1/po-bs01 m_002.PNG",
                            "../img/p+1/po-bs01 m_003.PNG",
                            "../img/p+1/po-bs01 m_006.PNG",
                            "../img/p+1/PO-BS01 M_007.PNG",
                            "../img/p+1/po-bs01 m_008.PNG",
                        ],
                        productThumbnail: "../img/p+1/po-bs01 m_001.PNG"
                    }
                ]
            },
            {
                category: "Jordan Cavalli",
                slug: "jordan-cavalli",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.PNG",
                            "/img/jc_boy/2.PNG",
                            "/img/jc_boy/3.PNG",
                            "/img/jc_boy/4.PNG"
                        ],
                        productThumbnail: "/img/jc_boy/1.PNG"
                    },
                ]
            },
            {
                category: "Pierre Uno",
                slug: "pierre-uno",
                listProduct: [
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.PNG",
                            "/img/pu/pu-boxer1/bx2.PNG",
                            "/img/pu/pu-boxer1/bx3.PNG",
                            "/img/pu/pu-boxer1/bx4.PNG",
                            "/img/pu/pu-boxer1/bx5.PNG",
                            "/img/pu/pu-boxer1/bx6.PNG",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.PNG"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-boxer2/bx1.PNG",
                            "../img/pu/pu-boxer2/bx2.PNG",
                            "../img/pu/pu-boxer2/bx3.PNG",
                            "../img/pu/pu-boxer2/bx4.PNG",
                            "../img/pu/pu-boxer2/bx5.PNG",
                            "../img/pu/pu-boxer2/bx6.PNG",
                        ],
                        productThumbnail: "../img/pu/pu-boxer2/bx1.PNG"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.PNG",
                            "../img/pu/pu-b01m/img_0026.PNG",
                            "../img/pu/pu-b01m/img_0030.PNG",
                            "../img/pu/pu-b01m/img_0032.PNG",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.PNG"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b05m/img_0089.PNG",
                            "../img/pu/pu-b05m/img_0092.PNG",
                            "../img/pu/pu-b05m/img_0096.PNG",
                            "../img/pu/pu-b05m/img_0097.PNG",
                            "../img/pu/pu-b05m/img_0098.PNG",
                            "../img/pu/pu-b05m/img_0099.PNG",
                        ],
                        productThumbnail: "../img/pu/pu-b05m/img_0089.PNG"
                    },
                ]
            },
            {
                category: "P+1",
                slug: "p+1",
                listProduct: [
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/po-bs01 m_001.PNG",
                            "../img/p+1/po-bs01 m_002.PNG",
                            "../img/p+1/po-bs01 m_003.PNG",
                            "../img/p+1/po-bs01 m_006.PNG",
                            "../img/p+1/PO-BS01 M_007.PNG",
                            "../img/p+1/po-bs01 m_008.PNG",
                        ],
                        productThumbnail: "../img/p+1/po-bs01 m_001.PNG"
                    }
                ]
            },
        ]
    },
    {
        category: "WOMEN",
        productList: [
            {
                category: "all",
                slug: "all",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.PNG",
                            "/img/jc_boy/2.PNG",
                            "/img/jc_boy/3.PNG",
                            "/img/jc_boy/4.PNG"
                        ],
                        productThumbnail: "/img/jc_boy/1.PNG"
                    },
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.PNG",
                            "/img/pu/pu-boxer1/bx2.PNG",
                            "/img/pu/pu-boxer1/bx3.PNG",
                            "/img/pu/pu-boxer1/bx4.PNG",
                            "/img/pu/pu-boxer1/bx5.PNG",
                            "/img/pu/pu-boxer1/bx6.PNG",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.PNG"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-boxer2/bx1.PNG",
                            "../img/pu/pu-boxer2/bx2.PNG",
                            "../img/pu/pu-boxer2/bx3.PNG",
                            "../img/pu/pu-boxer2/bx4.PNG",
                            "../img/pu/pu-boxer2/bx5.PNG",
                            "../img/pu/pu-boxer2/bx6.PNG",
                        ],
                        productThumbnail: "../img/pu/pu-boxer2/bx1.PNG"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.PNG",
                            "../img/pu/pu-b01m/img_0026.PNG",
                            "../img/pu/pu-b01m/img_0030.PNG",
                            "../img/pu/pu-b01m/img_0032.PNG",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.PNG"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b05m/img_0089.PNG",
                            "../img/pu/pu-b05m/img_0092.PNG",
                            "../img/pu/pu-b05m/img_0096.PNG",
                            "../img/pu/pu-b05m/img_0097.PNG",
                            "../img/pu/pu-b05m/img_0098.PNG",
                            "../img/pu/pu-b05m/img_0099.PNG",
                        ],
                        productThumbnail: "../img/pu/pu-b05m/img_0089.PNG"
                    },
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/po-bs01 m_001.PNG",
                            "../img/p+1/po-bs01 m_002.PNG",
                            "../img/p+1/po-bs01 m_003.PNG",
                            "../img/p+1/po-bs01 m_006.PNG",
                            "../img/p+1/PO-BS01 M_007.PNG",
                            "../img/p+1/po-bs01 m_008.PNG",
                        ],
                        productThumbnail: "../img/p+1/po-bs01 m_001.PNG"
                    }
                ]
            },
            {
                category: "Jordan Cavalli",
                slug: "jordan-cavalli",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.PNG",
                            "/img/jc_boy/2.PNG",
                            "/img/jc_boy/3.PNG",
                            "/img/jc_boy/4.PNG"
                        ],
                        productThumbnail: "/img/jc_boy/1.PNG"
                    },
                ]
            },
            {
                category: "Pierre Uno",
                slug: "pierre-uno",
                listProduct: [
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.PNG",
                            "/img/pu/pu-boxer1/bx2.PNG",
                            "/img/pu/pu-boxer1/bx3.PNG",
                            "/img/pu/pu-boxer1/bx4.PNG",
                            "/img/pu/pu-boxer1/bx5.PNG",
                            "/img/pu/pu-boxer1/bx6.PNG",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.PNG"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-boxer2/bx1.PNG",
                            "../img/pu/pu-boxer2/bx2.PNG",
                            "../img/pu/pu-boxer2/bx3.PNG",
                            "../img/pu/pu-boxer2/bx4.PNG",
                            "../img/pu/pu-boxer2/bx5.PNG",
                            "../img/pu/pu-boxer2/bx6.PNG",
                        ],
                        productThumbnail: "../img/pu/pu-boxer2/bx1.PNG"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.PNG",
                            "../img/pu/pu-b01m/img_0026.PNG",
                            "../img/pu/pu-b01m/img_0030.PNG",
                            "../img/pu/pu-b01m/img_0032.PNG",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.PNG"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b05m/img_0089.PNG",
                            "../img/pu/pu-b05m/img_0092.PNG",
                            "../img/pu/pu-b05m/img_0096.PNG",
                            "../img/pu/pu-b05m/img_0097.PNG",
                            "../img/pu/pu-b05m/img_0098.PNG",
                            "../img/pu/pu-b05m/img_0099.PNG",
                        ],
                        productThumbnail: "../img/pu/pu-b05m/img_0089.PNG"
                    },
                ]
            },
            {
                category: "P+1",
                slug: "p+1",
                listProduct: [
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/po-bs01 m_001.PNG",
                            "../img/p+1/po-bs01 m_002.PNG",
                            "../img/p+1/po-bs01 m_003.PNG",
                            "../img/p+1/po-bs01 m_006.PNG",
                            "../img/p+1/PO-BS01 M_007.PNG",
                            "../img/p+1/po-bs01 m_008.PNG",
                        ],
                        productThumbnail: "../img/p+1/po-bs01 m_001.PNG"
                    }
                ]
            },
        ]
    }
]